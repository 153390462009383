import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import TV from "../images/static.webp"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="m-auto px-10 my-4 font-sans max-w-6xl">
      <div className="m-auto">
      <div className="my-8"><img className="m-auto w-80" src={TV} alt="Static TV" /></div>
      <h1 className="text-center mb-0">Looks like this page is off the air.</h1>
      <p className="text-center">Try heading back to <Link to="/">Home</Link> or going back to the last page you were on.</p>
    </div>
    </div>
  </Layout>
)

export default NotFoundPage
